export interface IPagetreeModel<PagetreeItem extends IPagetreeItem<PagetreeItem>> {
	childItems: PagetreeItem[];
}

export interface IPagetreeItem<ChildItem extends IPagetreeItem<ChildItem>> {
	key: string;
	primaryText: string;
	secondaryText: string;
	childItems: ChildItem[];
	color: string;
	icon: string;
	tags: string[];
}

export type TPagetreeItem = IPagetreeItem<TPagetreeItem>;
export type TPagetreeModel = IPagetreeModel<TPagetreeItem>;

export function getPagetreeItemByKey<PagetreeItem extends IPagetreeItem<PagetreeItem>>(key: string | null, pagetree: IPagetreeModel<PagetreeItem> | null): PagetreeItem | null {
	if (!key || !pagetree) {
		return null;
	}
	return getItemByKeyRecursive<PagetreeItem>(key, pagetree.childItems);
}

function getItemByKeyRecursive<PagetreeItem extends IPagetreeItem<PagetreeItem>>(key: string, items: PagetreeItem[] | null): PagetreeItem | null {
	if (!items) {
		return null;
	}
	for (const item of items) {
		if (item.key === key) {
			return item;
		}
		const childItem = getItemByKeyRecursive(key, item.childItems);
		if (childItem) {
			return childItem;
		}
	}
	return null;
}